<template>
  <section class="m-aftersale-address m-scroll">
    <!-- 头部导航 -->
    <m-header title="Shipping Address"></m-header>
    
    <ul class="list">

      <transition-group name="van-slide-down">
        <li class="item" v-for="i in data" :key="i">

          <van-radio :name="i" v-model="checked">
              <div class="name-phone">
                <div>
                  <img src="@/assets/address/icon_name@2x.png" alt="name">
                  <span>Lenora Alice</span>
                </div>
                <div>
                  <img src="@/assets/address/icon_tel@2x.png" alt="name">
                  <span>7765443288</span>
                </div>
              </div>
              <div class="addr">
                <img src="@/assets/address/icon_dress@2x.png" alt="">
                <p>Toronto Lyndon F. Mascarenhas 200 St-Clair Avenue West, Suite101 - W, Toronto, Ontario,</p>
              </div>
              <div class="edit van-hairline--top">
                <div class="btns">
                  <div><span>Edit</span></div>
                  <div @click="handleDelete()"><span>Delete</span></div>
                </div>
              </div>

          </van-radio>
  
          
        </li>
      </transition-group>
      
    </ul>

    <div class="footer">
      <router-link to="/en/address/edit">Add Address</router-link>
    </div>

  </section>
</template>

<script>
import MHeader  from '@/components/en/m-header.vue'

export default {
  name:'Index',
  components:{ MHeader },
  data(){
    return {
      checked: '',
      data:2,
    }
  },

  mounted(){
    // this.$refs.home.

  },

  methods:{

    // 删除地址
    handleDelete(){
      this.data --
      this.$notify({ type: 'success', duration: 2000, message: '删除成功' })
    },

    //设置默认地址
    handleDefalut(i){
      this.defalut = i
      this.$notify({ type: 'success', duration: 2000, message: '成功设置默认地址' })
    }, 
  }
}
</script>

<style lang="less" scoped>
@import './address.less';
</style>